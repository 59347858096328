<template>
  <div>
    <section
      v-if="allowedAccess"
      class="content box-management"
    >
      <div class="row">
        <div class="col-sm-12">
          <div class="box box-default box-solid">
            <div class="box-body">
              <form>
                <div
                  v-if="model.states !== null"
                  class="row"
                >
                  <div class="col-sm-12">
                    <h2>Stany procesu</h2>
                    <list
                      :items="model.states"
                      :empty-element="createEmptyElement()"
                      @modified="forceToSave"
                    >
                      <template
                        slot="element"
                        slot-scope="listItems"
                      >
                        <state-type
                          v-model="listItems.item"
                          :row-index="listItems.index"
                          :roles="roles"
                          @modified="forceToSave"
                        />
                      </template>
                    </list>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
    <save-data-information
      :show="saveDataInformationVisible"
      @close="saveDataInformationVisible = false"
    />
    <login-modal
      :show="loginVisible"
      :back-url-name="loginModalBackUrlName"
      @close="loginVisible = false"
      @success="init()"
    />
  </div>
</template>

<script>
import _ from 'lodash'
import Page from '../../../../../share/content/Page'
import api from '../../../../../../api'
import List from '../../../../../share/form/List'
import LoginModal from '../../../../share/modal/LoginModal'
import StateType from './StateTypeMain.vue'
import SaveDataInformation from '../../../../share/modal/SaveDataInformation'
import clientObjectMixin from '../../../share/mixins/clientObjectMixin'

export default {
  components: {
    StateType,
    List,
    LoginModal,
    SaveDataInformation
  },
  mixins: [
    Page,
    clientObjectMixin
  ],
  data () {
    return {
      events: {
        stateSubmit: ''
      },
      rolesLoaded: false,
      statesLoaded: false,
      roles: [],
      headerTitle: { title: 'Stany procesu', description: 'Tryb edycji' },
      loginVisible: false,
      allowedAccess: false,
      model: this.setModel(),
      isSaved: true,
      saveDataInformationVisible: false,
      service: '',
      loginModalBackUrlName: ''
    }
  },
  mounted () {
    this.$nextTick(() => {
      this.loginVisible = true
      this.$events.on(this.events.stateSubmit, this.submitData)
    })
  },
  methods: {
    init () {
      this.allowedAccess = true
      this.loadRoles()
      this.loadStates()
    },
    setModel () {
      return {
        states: []
      }
    },
    createEmptyElement () {
      return {
        name: null,
        initial: false,
        final: false,
        assigneeType: 1,
        accessRoles: []
      }
    },
    loadRoles () {
      api.request(this.service, 'get', '/roles')
        .then((response) => {
          const roles = response.data
          for (let c = 0; c < roles.length; ++c) {
            this.roles.push({ id: roles[c].id, value: roles[c].name, label: roles[c].label + (roles[c].businessRole ? ' (rola biznesowa)' : ''), name: roles[c].name })
          }
          this.rolesLoaded = true
        })
        .catch(() => {
          this.$notify({
            type: 'error',
            title: 'Wystąpił błąd',
            text: 'Nie można załadować ról'
          })
        })
    },
    loadStates () {
      api.request(this.service, 'get', `/workflows/1/states?format=admin-list`)
        .then((response) => {
          const states = response.data
          for (let c = 0; c < states.length; ++c) {
            this.model.states.push(
              {
                id: states[c].id,
                label: states[c].label,
                name: states[c].name,
                initial: states[c].initial,
                final: states[c].final,
                assigneeType: states[c].assigneeType,
                accessRoles: _.map(states[c].accessRoles, function (item) { return item.id })
              }
            )
          }
          this.statesLoaded = true
        })
        .catch(() => {
          this.$notify({
            type: 'error',
            title: 'Wystąpił błąd',
            text: 'Nie można załadować stanów'
          })
        })
    },
    forceToSave () {
      this.isSaved = false
    },
    submitData () {
      let submitData = { states: [] }
      for (let c = 0; c < this.model.states.length; ++c) {
        submitData.states.push({
          id: this.model.states[c].id,
          name: this.model.states[c].label,
          initial: this.model.states[c].initial,
          final: this.model.states[c].final,
          assigneeType: this.model.states[c].assigneeType,
          accessRoles: this.model.states[c].accessRoles
        })
      }
      api.request(this.service, 'put', `/workflows/1/states`, submitData)
        .then(() => {
          this.isSaved = true
          this.$notify({
            type: 'success',
            title: 'Sukces',
            text: 'Dane zapisane poprawnie'
          })
        })
        .catch((error) => {
          this.$notify({
            type: 'error',
            title: 'Wystąpił błąd',
            text: `Błąd zapisu danych: ${error.message}`
          })
        })
    }
  }
}
</script>

<style scoped>
h2 {
    font-size: 18px
}
</style>
