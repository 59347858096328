<template>
  <div class="row">
    <input
      v-if="value.id"
      :id="'id_' + value.id"
      type="hidden"
      :value="value.id"
    >
    <div class="col-sm-4">
      <div>
        <label for="name">Nazwa</label>
        <input
          id="name"
          class="form-control"
          :value="value.label"
          @input="updateLabel($event.target.value)"
        >
      </div>
    </div>
    <div class="col-sm-4">
      <div class="form-group checkbox">
        <div class="form-checkbox">
          <input
            :id="`initial${rowIndex}`"
            v-model="value.initial"
            type="checkbox"
            @change="emitModificationSignal"
          >
          <label :for="`initial${rowIndex}`">Status początkowy</label>
        </div>
      </div>
      <div class="form-group checkbox">
        <div class="form-checkbox">
          <input
            :id="`final${rowIndex}`"
            v-model="value.final"
            type="checkbox"
            @change="emitModificationSignal"
          >
          <label :for="`final${rowIndex}`">Status końcowy</label>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    value: Object,
    rowIndex: { type: Number, required: true }
  },
  methods: {
    emitModificationSignal () {
      this.$emit('modified')
    },
    updateLabel (value) {
      this.value.label = value
      this.$emit('input', this.value)
      this.emitModificationSignal()
    }
  }
}
</script>

<style scoped>
.input-group {
    width: 100%;
}
</style>
